.customChannelSetup {

    .backdiv {
        margin-bottom: 20px;
        cursor: pointer;
        text-align: left;

        .backIcon {
            margin-right: 5px;
        }
    }

    .channel-form {
        position: relative;

        .channel-icon {
            display: inline-block;
            border: 1px solid #ddd;
            border-radius: 4px;
            position: absolute;
            left: 300px;
            top: 8%;
            font-size: 14px;
            padding: 2px 5px;
            color: #526179;
        }
    }

    .customChanneltitle {
        font-weight: 500;
        font-size: 30px;
        color: #526179;
        text-align: left;
        margin-top: 30px;
        margin-bottom: 30px;
        width: 250px;

    }

    .channelheadertitle {
        font-weight: normal;
        font-size: 12px;
        color: #526179;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .storename {
        background: #EEF3F8;
        border: 1px solid #EEF3F8;
        width: 400px;
        height: 60px;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .type_btn {
        //width: 400px;
        text-align: left;

        .btn-primary {
            text-align: center;
            padding-top: 5px;
            box-sizing: border-box;
            margin-bottom: 30px;
            background: #ffffff;
            border-radius: 8px;
            color: #0D1220;
            border: 1px solid #EEF3F8;
            //width: 100%;
            height: 60px;
            box-sizing: border-box;

        }
    }

    .custom_info {
        border-radius: 8px;

        height: 220px;
        width: 387px;
        padding: 40px;
        margin-left: 100px;
        margin-top: 100px;
        background: #F3ECEC;
        border: 1px solid #F3ECEC;
    }

    .input_tab {
        width: 400px;
        height: 60px;
        background: #ffffff;
        border: 1px solid #EEF3F8;
        box-sizing: border-box;
        border-radius: 8px;

    }

    .submit_btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        width: 128px;
        height: 44px;
        background: #42D6C9;
        border-radius: 100px;
        color: #0D1220;
        border: #42D6C9;
        font-weight: 500;
    }
}