.ticketContents {
    margin-left: 0px;
    margin-right: 0px;

    .conversations {
        height: 100%;
        padding-top: 0px;

        &.emailConv {
            overflow-y: auto;
            height: calc(100vh - 141px);
        }

        &.customConv {
            overflow-y: auto;
            height: 90vh;
        }

        .can_reply {
            background-color: #FFFAE5;
            color: #664d03;
            border-color: #FFFAE5;
            padding: 10px;
            margin-bottom: 1rem;
            border: 1px solid transparent;
            border-radius: 10px;
            width: 75%;
            margin: 0 auto;
            font-size: 14px;

            .exclamation {
                font-size: 16px;
                margin-bottom: 0;
                font-weight: bold;
            }
        }

        .userProfile {
            display: flex;
            flex-direction: row;
            padding: 0px;
            text-align: left;
            align-items: center;
            margin-left: 10px;
            margin-top: 10px;

            &.insta {
                display: flex;
                flex-direction: row-reverse;
                text-align: end;
            }

            .initialsCircle {
                border-radius: 50%;
                width: 36px;
                height: 36px;
                background-color: #F7F9FC;
                color: #043854;
                display: inline-block;
                text-align: center;
                padding-top: 6px;
                font-size: 14px;

                &.owner {
                    background-color: #42D6C9;
                }
            }
        }
    }

    .facebook-parent {
        position: relative;
        display: grid;
        grid-template-rows: 1fr auto;
        //margin-bottom: 10px;

        .facebook_conversations {
            overflow-y: scroll;
            height: calc(100vh - 248px);

            .comment-div{
                display: grid;
                width: 300px;
                height: auto;
                text-align: left;
                margin-left: 10px;
                margin-top: 5px;
            }
        }

        .bottomTextarea {
            //width: 500px;
            bottom: 0;
            padding: 0;
            //background: #F7F9FC;
            border: 1px solid #ebeef0;
            box-sizing: border-box;

            //border-radius: 16px;
            margin: 15px;
            //display: grid;
            grid-template-columns: 1fr 20px;

            .header {
                width: 100%;
                height: 20px;
                background: #F7F9FC;

            }

            textarea {
                border: none;
                outline: none;
                min-height: 20px;
                text-overflow: ellipsis;
                overflow-y: scroll;
                resize: none;
                //background: #F7F9FC;
                width: 100%;
                padding: 5px 10px 0;
            }

            .bellIcon {
                position: absolute;
                // top: 15px;
                z-index: 9;
                cursor: pointer;
                right: 25px;
                bottom: 15px;
            }
        }
    }

}


.imagestyle {
    width: 50px;
    float: left;
    margin: 0px;
}

.nameStyle {
    margin-left: 12px;
    margin-bottom: 0px;
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    &.insta {
        margin-right: 12px;
        margin-left: 0;   
    }

}

.dummy {
    visibility: hidden;
}

.quillWrapper {
    padding: 10px 25px;
    position: relative;
    height: 130px;

    .quillEditor {
        border: none;
        box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        height: 130px;

        .ql-toolbar.ql-snow {
            border: none;
            position: absolute;
            bottom: 0;
            width: 80%;
            margin: 0 auto;
            border-top: .51px solid #ddd;
            left: 0;
            right: 0;
            height: 35px;
        }

        .ql-container {
            border: none;
        }
    }
}

.customUpdates {
    padding: 20px;

    .actions {
        margin-top: 10px;
        text-align: right;

        .errorMez {
            display: inline-block;
            margin-right: 20px;
            color: red;
        }

        .cancel {
            background-color: #fff;
            color: #043854;
            border-color: #043854;
        }

        .save {
            margin-right: 10px;
            background-color: #043854;
            border-color: #043854;
            width: 120px;
        }
    }

    .quill-content {
        height: 250px;

        .quill {
            height: 75%;
        }
    }
}

.customWrapper {
    box-shadow: 0px 8px 33px 8px rgba(24, 39, 75, 0.09);
    border-radius: 10px;
    overflow: scroll;
    margin: 12px;
    background-color: #fff;
    padding: 10px 15px;
    text-align: left;
    position: relative;
    scrollbar-width: none;

    .ticketheader {
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;

        .ticketdetails {
            font-size: 14px;
            font-weight: bold;
        }

        .timestamp {
            font-size: 12px;
            color: #8292AA;
            text-align: right;
        }
    }

    .customContent {
        padding-top: 15px;
        font-size: 14px;

        .customTextHeader {
            font-size: 15px;
            font-weight: 400;

        }

        .customText {
            font-size: 15px;
            color: #8292AA;
        }

        .textEmail {
            font-size: 14px;
        }

        .details {
            margin-top: 10px;
            font-size: 16px;
        }
    }
}

.customWrapper::-webkit-scrollbar {
    display: none;
}

.update-ticket-btn {
    padding: 4px 8px;
    width: 134px;
    height: 34px;
    border: 1px solid #043854;
    border-radius: 20px;
    background: #E9FDF3;
    font-size: 14px;
    color: #043854;
    margin: 15px auto;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.sender {
    // margin-left: 64px;
    position: relative;
    //box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, .12);
    border-radius: 2px;
    padding-left: 8px;

    &.emailSender {
        margin-left: 0;
        padding: 2px 15px;
    }

    .time-style {
        color: #8292AA;
        font-size: 10px;
        padding-bottom: 5px;
        display: flex;
        margin-left: 50px;
    }

    .convElement {
        max-width: 50%;
        margin-bottom: 5px;

        &.emailConversation {
            max-width: 100%;
            background: transparent;
            margin-top: 10px;
            margin-right: 10px;
        }

        .convReply {
            border-top: solid 1px #C0CBDA;

            .replyBtn {
                background-color: #42D6C9;
                color: #fff;
                border-radius: 100px;
                border: none;
                margin-top: 5px;
                padding: 5px 20px;
                font-size: 14px;
                font-weight: 500;

                .replyIcon {
                    color: #fff;
                    margin-right: 6px;
                    font-size: 14px;
                }
            }
        }

        .instaSenderWrapper{
            padding: 0px 0px;
            border-radius: 4px;
            background-color: #fff;
            width: fit-content;
            border: 1px solid #DBE2ED;
            box-sizing: border-box;
            box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
            margin-top: 10px;

            .borderSeparate{
                border-bottom: 1px solid #ddd;
            }

            .instaReplyBtn{
                background-color: #074465;
                color: #fff;
                border-radius: 4px;
                text-align: center;
                width: fit-content;
                padding: 5px 8px;
                overflow-wrap: break-word;
                margin: 5px 10px;
                width: 100px;
                cursor: pointer;
                box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
            }
        }
        .senderStyle {
            background-color: #edf1f7;
            text-align: left;
            width: fit-content;
            padding: 5px 8px;
            overflow-wrap: break-word;
            box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-radius: 0px 8px 8px 8px;
            font-size: 14px;
            margin-left: 57px;
            
            &.instagram{
                margin-left: 0;
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
                border-radius: 4px;
                background-color: #fff;
            }



            img {
                max-width: 350px;
                object-fit: contain;
            }
        }
    }

    .emailWrapper {
        box-shadow: 0px 8px 33px 8px rgba(24, 39, 75, 0.09);
        border-radius: 10px;
        overflow: scroll;
        width: 100%;
        background-color: #fff;
        padding: 5px;
        text-align: left;
        position: relative;
        scrollbar-width: 0px;

        &::-webkit-scrollbar {
            display: none;
        }

        .emailAlert {
            position: absolute;
            top: 10px;
        }

        .emailContent {
            overflow-x: auto;

            .textEmail {
                .email-codes {
                    font-size: 18px;

                }
            }
        }

        .replybutton {
            width: 116px;
            height: 40px;
            background: #FFFFFF;
            border: 1px solid #DBE2ED;
            border-radius: 100px;
            color: #0D1220;
            margin-bottom: 10px;
            margin-top: 15px;

            &:hover {
                color: #fff;
                background: #42D6C9;
                border-radius: 100px;
                border: #0D1220;
            }
        }

        .emailHeader {
            padding: 5px 5px 10px 2px;
            border-bottom: 1px solid #eee;
            text-align: left;
            position: relative;
            cursor: pointer;

            &.noBorder {
                border: none;
            }

            .sendingInfo {
                position: relative;
                font-size: 14px;
                //box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
                padding-left: 8px;
                padding-bottom: 5px;

                .expandStatus {}

                .envelopeExpand {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    cursor: pointer;

                    svg {
                        color: #8292AA
                    }
                }

                .info {
                    font-size: 12px;
                }

            }

            .email_popover {
                position: absolute;
                top: 22px;
                right: 10px;
                background-color: #fff;
                border: 1px solid #eee;
                padding: 8px;
                border-radius: 8px;
                width: 90px;
                box-shadow: 0px 8px 33px 8px rgba(24, 39, 75, 0.09);


                .option {
                    font-size: 15px;
                    margin-bottom: 4px;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }

            .optionCol {
                padding-left: 0;
                padding-right: 0;

                .timeStyle {
                    color: #8292AA;
                    position: relative;
                    bottom: 2px;
                    float: none;
                    font-size: 11px;
                    margin-right: 0;
                    margin-left: 2px;
                }

                .more_options {
                    color: #8292AA;
                    font-size: 15px;
                    cursor: pointer;
                    margin-left: 7px;
                    margin-top: 5px;
                }
            }

            .initials {
                background: rgb(229, 229, 229);
                width: 30px;
                height: 30px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 500px;
                font-size: 12px;
                font-weight: bold;
                position: relative;
                top: 5px;
            }
        }

        .ticketdetailsheader {
            padding-left: 15px;

        }
    }
}

.receiver {
    // margin-left: 64px;
    position: relative;
    border-radius: 2px;
    padding-left: 8px;

    &.insta{
        padding-left: 0;
        padding-right: 8px;
        // display: grid;
        // grid-template-columns: 50px 1fr;
    }

    .timeStamp {
        color: #8292AA;
        font-size: 10px;
        padding-bottom: 5px;
        display: flex;
        margin-left: 50px;

        &.insta {
            flex-direction: row-reverse;
        }
    }


    .receiverStyle {
        text-align: left;
        width: fit-content;
        max-width: 70%;
        padding: 5px 8px;
        overflow-wrap: break-word;
        border-radius: 0px 8px 8px 8px;
        background-color: #FFFAE5;
        font-size: 14px;
        margin-left: 50px;
        margin-bottom: 5px;

        &.insta{
            text-align: right;
            margin-left: auto;
            max-width: max-content;
            margin-top: 10px;
        }
    }

}



.sendemailbutton {
    margin: 8px;
    width: 116px;
    height: 40px;
    background: #42D6C9;
    border: 1px solid #DBE2ED;
    border-radius: 100px;
    color: #0D1220;
    position: absolute;
    bottom: 20px;
}

.sendemailbutton:hover {
    color: #ffffff;
    background: #42D6C9;
    border-radius: 100px;
    border: 1px solid #DBE2ED;
}

.sentEmail {
    margin-top: 20px;
}

.conversationReply {
    margin-top: 10px;
}