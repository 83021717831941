.integrationWrapper {
    padding-top: 20px;

    .inte_table {

        .shop_email {
            display: inline-block;
            margin-left: 0px;
            font-size: 15px;
        }

        thead {
            th {
                border-bottom: none;
                border-top: none;
                text-align: left;
                font-size: 18px;
                font-weight: 500;
                color: #0D1220;
            }
        }

        td {
            border-top: none;
            text-align: left;

            .more_options {
                color: #8292AA;
            }

            .connection_status {
                background: #E9FDF3;
                border-radius: 8px;
                width: 130px;
                text-align: center;
                padding: 5px;
            }
        }
    }


    .integrations_add {
        text-align: left;

        .instamojo_btn {
            padding: 8px 30px;
            border-radius: 40px;
        }
    }

    .header {

        .add_btn_div {
            text-align: right;

            .btnNew {
                border-radius: 100px;
                border: none;
                padding: 10px 40px;
                background-color: #42D6C9;
                color: #043854;
            }
        }

        .header_first {
            text-align: left;

            .integration_header {
                font-size: 20px;
                color: #0D1220;

                font-weight: 500;
            }
        }
    }
}

.woo_store_parent {


    .gobackdiv {
        text-align: left;
    }

    .woo_comm_form {
        padding-top: 30px;
        text-align: left;

        .reqd_message {
            font-size: 12px;
        }

        .error_mess {
            color: red;
            font-size: 13px;

        }

        .start_integration {
            border-radius: 100px;
            border: none;
            padding: 10px 40px;
            background-color: #42D6C9;
            color: #043854;
            margin-top: 10px;

            &.deactivate {
                background-color: #ddd;
                cursor: default;
                pointer-events: none;
            }
        }

        .com_section {
            background: #FFFFFF;
            border: 1px solid #EEF3F8;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 20px;
            margin-top: 20px;

            .name_div {
                margin-bottom: 20px;

                .contents {

                    .req {
                        color: red;
                    }

                    .input {
                        background: #F7F9FC;
                        border: 1px solid #EEF3F8;
                        box-sizing: border-box;
                        border-radius: 8px;
                        height: 48px;
                        padding: 0 4px;
                        width: 100%;
                        max-width: 500px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.shopify_parent {
    .shopify_gobackdiv {
        text-align: left;

        .backIcon{
            margin-right: 5px;
        }
    }

    .shopify_form {
        padding-top: 30px;
        text-align: left;

        .heading {
            display: inline-block;
            margin-right: 10px;
        }

        .shopify_reqd_message {
            font-size: 12px;
        }

        .shopify_error_mess {
            color: red;
            font-size: 13px;

        }

        .shopify_start_integration {
            border-radius: 100px;
            border: none;
            padding: 10px 40px;
            background-color: #42D6C9;
            color: #043854;
            margin-top: 10px;

            &.deactivate {
                background-color: #ddd;
                cursor: default;
                pointer-events: none;
            }
        }

        .shopify_com_section {
            background: #FFFFFF;
            border: 1px solid #EEF3F8;
            box-sizing: border-box;
            border-radius: 8px;
            padding: 20px;
            margin-top: 20px;

            .permission_span{
                display: inline-block;
                margin-left: 10px;
                position: relative;
                bottom: 5px;
                color: #989899;
            }

            .shopify_name_div {
                margin-bottom: 20px;

                .shopify_contents {

                    .shopify_req {
                        color: red;
                    }

                    .shopify_input {
                        background: #F7F9FC;
                        border: 1px solid #EEF3F8;
                        box-sizing: border-box;
                        border-radius: 8px;
                        height: 48px;
                        padding: 0 4px;
                        width: 100%;
                        max-width: 500px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.addIntegrationsWrapper {

    .gobackdiv {
        text-align: left;
        cursor: pointer;

        .backIcon {
            margin-right: 5px;
        }
    }

    .integration_list {
        padding-top: 40px;

        .integration {
            text-align: left;
            //border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
            margin-left: 0;
            margin-right: 0;

            .integration_col {

                .heading {
                    font-weight: bold;
                }

                .description {
                    font-size: 12px;
                }
            }

            .inte_add {
                .add_btn {

                    margin-top: 10px;
                    display: inline-block;
                    border: 1px solid #ddd;
                    padding: 2px 10px;
                    border-radius: 15px;
                    font-size: 14px;

                    .addIcon {
                        margin-right: 2px;
                    }

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.hide {
    display: none;
}