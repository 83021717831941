.sidebarContainer {
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
    height: 100%;

    .addChannel {
        width: 112px;
        font-size: 12px;
        margin: 10px auto;
        display: block;
        padding: 5px 0;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 100px;

        .add {
            margin-right: 4px;
            display: inline-block;
        }
    }

    .logo {
        padding-top: 18px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        margin-left: 0;
        margin-right: 0;
        color: #fff;

        .mezLogo {
            padding-right: 0;
            padding-left: 0;

            .mezchip_logo {
                padding: 5px 0px;
                overflow: hidden;
                font-size: 20px;
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .logoStyle {
            width: 25px;
            padding-left: 5px;
        }

        .bellWrapper {
            position: relative;
            cursor: pointer;

            &:hover {
                .notification_symbol {
                    width: 12px;
                    height: 12px;
                    transition: .6s ease;
                }
            }

            .bellIcon {}

            .notification_symbol {
                position: absolute;
                width: 9px;
                height: 9px;
                border-radius: 50%;
                background-color: #F9C933;
                top: 0px;
                right: 5px;
            }
        }

    }

    .getting-started {

        .start-gift {
            color: #42D6C9;
            position: relative;
            left: -10px;
        }

        .text {
            color: white;
            font-size: 16px;
            display: inline-block;
            cursor: pointer;
            //font-weight: bold;
        }
    }

    .settingsWrapper {
        padding: 20px 10px 0;
        text-align: left;

        .settings-heading{
            color: #fff;
            font-size: 16px;
            font-weight: bold;
        }

        .category-text {
            margin-top: 10px;
            color: #fff;
            margin-bottom: 5px;
            font-size: 14px
        }

        .options {
            text-align: left;
            border-radius: 8px;
            padding: 12px;
            cursor: pointer;
            transition: all 0.3s;

            &.active {
                background: #074465;
            }

            .text {
                color: #7496A8;
                margin-bottom: 0;
                transition: all 0.2s;
                font-size: 14px;
            }

            &:hover {
                background-color: #033e5e;
            }

            &:hover .text {
                color: #C0CBDA;
            }
        }

    }

    .menuWrapper {
        margin-top: 0px;
        padding-left: 5px;
        height: calc(100vh - 120px);
        overflow-y: auto;

        
        .menu {
            color: #717e8e;
            text-align: left;
            padding: 10px 2px;
            padding-top: 0px;
            position: relative;
            transition: all 300ms linear;
            border-radius: 3px;
            cursor: pointer;
            // margin-bottom: 15px;

            .logoStyle {
                width: 20px;
            }

            .menuName {
                font-size: 14px;
                font-weight: 500;
                margin-left: 10px;
                color: #fff;
            }

            &.active {
                color: #42D6C9;


                .menuName {

                    color: #42D6C9;
                }
            }

            p {
                margin-bottom: 0;
                margin-left: 10px;

                svg {
                    margin-right: 15px;
                }
            }

            .teamscard{
                background-color: transparent;
                border: 0px;

                .channelcardheader {
                    background-color: transparent;
                    border: 0px;
                    padding: 0px;
                }

                .teamsComponent{
                        position: relative;
                        
                        .cardbody{
                            border-radius: 8px;
                            padding: 6px 0;
                            font-size: 12px;

                            .memberName{
                                
                                .name{
                                    font-size: 14px;
                                    font-weight: 400;
                                    color: #7496A8;
                                    width: 138px;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: inline-block;

                                    .role{
                                        margin-left: 4px;
                                    }
                                }
                                
                                .nameDiv{
                                    display: grid;
                                    grid-template-columns: 20px auto;
                                    padding: 5px 0;

                                    .initials{
                                        //background: #e5e5e5;
                                        width: 15px;
                                        height: 15px;
                                        text-align: center;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border-radius: 50px;
                                        font-size: 12px;
                                        font-weight: bold;
                                        position: relative;
                                        top: 3px;
                                    }
                                }
                            }
                        }
                    }

                .cardbody {
                    padding: 0px;
                    padding-top: 5px;
                }

                .arrowdownbutton {
                    float: right;
                    padding: 0px;
                }
                .rotate{
                    transform: rotateX(180deg);
                }
            }
            .channelcard {
                background-color: transparent;
                border: 0px;

                .ChannelsComponent {
                    .channelLoader {
                        text-align: center;
                    }

                    .spinner {
                        margin-top: 20px;
                        margin-left: 50px;
                    }
                }
            }

            .channelcardheader {
                background-color: transparent;
                border: 0px;
                padding: 0px;
            }

            .cardbody {
                padding: 0px;
                padding-top: 5px;
            }

            .arrowdownbutton {
                float: right;
                padding: 0px;
            }
            .rotate{
                transform: rotateX(180deg);
            }

            .tagsLogo {
                width: 15px;
                color: #7496A8;
                position: relative;
                bottom: 5px;
            }

            .channelLogo {
                width: 25px;
                color: #7496A8;
                position: relative;
                bottom: 5px;
            }

            .channelName {
                font-size: 12px;
                font-weight: 400;
                //margin-left: 5px;
                color: #7496A8;
                width: 138px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
            }

            .tagsstyle {
                border-radius: 2px;
                padding: 8px 0;
                font-size: 12px;

                .noTags {
                    font-size: 12px;
                }

                .tagsGrid {
                    // display: grid;
                    // grid-template-columns: 1fr auto;
                    // grid-column-gap: 5px;
                    // column-gap: 5px;
                    // padding-right: 5px;
                    display: flex;
                    align-items: center;
                    width: 100%;

                    .tagsTicketCount {
                        // color: #fff;
                        background: #FAFCFE;
                        border-radius: 8px;
                        display: inline-block;
                        width: 19px;
                        height: 19px;
                        text-align: center;
                        color: #043854;
                        font-size: 10px;
                        position: relative;
                        // bottom: 4px;
                        padding-top: 2px;
                        font-weight: 500;
                        margin-left: 5px;
                    }
                }

                &.currentTagName {
                    padding-left: 20px;
                    border-radius: 8px;
                    position: relative;
                    width: 100%;
                    display: flex;
                    align-items: center;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 5px;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        transform: translateY(-50%);
                        background-color: #7496a8;
                    }

                    &:hover {
                        background-color: #074465;
                    }
                }

                &.active {
                    background-color: #074465;

                    .channelstyle {
                        background-color: #074465;
                    }
                }
            }

            .channelstyle {
                border-radius: 8px;
                padding: 6px 0;
                font-size: 12px;

                &.active {
                    background-color: #074465;

                    .channelstyle {
                        background-color: #074465;
                    }
                }

                .unreadVal {
                    background: #FAFCFE;
                    border-radius: 8px;
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    text-align: center;
                    color: #043854;
                    font-size: 10px;
                    position: relative;
                    bottom: 4px;
                    padding-top: 2px;
                    font-weight: 500;
                }
            }

        }
    }

    .profilewrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        padding-top: 18px;
        border-top: 1px solid #ddd;
        padding-bottom: 10px;
        margin-left: 0;
        margin-right: 0;
        color: #fff;
        background-color: #074465;
        display: flex;
        align-items: center;

        .logout-icon {
            font-size: 22px;
        }

        .menu {
            position: absolute;
            bottom: 20px;
            left: 5px;
            width: 180px;
            display: none;
            border: 1px solid #ddd;
            border-radius: 4px;
            background-color: #fff;
            padding: 12px;

            .options {
                display: block;
                text-align: left;
                color: #043854;
                font-size: 14px;
                margin-bottom: 5px;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                .logoutStyle {
                    width: 15px;
                    margin-left: 5px;
                }
            }
        }

        .more-options {

            // &:hover {
            //     .menu {
            //         display: inline-block;
            //     }
            // }
        }

        .logout {
            cursor: pointer;
        }

        .profilepic {
            width: 30px;
        }

        .namestyle {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 13px;
            margin-bottom: 0;
            font-weight: 400;
            text-align: left;
            padding-left: 8px;
        }
        
        .emailstyle {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
            margin-bottom: 0;
            text-align: left;
            padding-left: 8px;
            padding-right: 10px;
            color: #C0CBDA;
        }
    }


}

.Model_main {
    width: 90vw;
    margin: 10px auto;
    text-align: center;
}

.my_modal {
    width: 70vw;
    margin: 0 auto;
    text-align: center;
    max-width: none !important;
}

.Carousel_container {
    margin: 0 auto !important;
    width: 90% !important;
}

.carousel_img {
    width: 100%;
    margin: 0 auto;
    height: auto;
    object-fit: cover;
    border-radius: 19px 19px 19px 19px;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.slick-dots {
    color: #043854;
    font-weight: bold;
}

.slider_main {
    padding: 0px 10px;
    width: 95% !important;
    margin: 0 auto !important;
}

.Carousel_title {
    margin-top: 15px;
}