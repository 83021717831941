.orderStatusWrapper{

    .orderstatusheader{
        font-weight: 400;
        font-size: 20px;
        margin-bottom:5px;
    }

    .dropdown-toggle.btn.btn-primary{
        background: #ffffff;
        color: black;
        border: 1px solid #42D6C9;
        border-radius: 100px;
        font-size: 15px;
        font-weight: 500;
    }

    .comments{
        margin-top: 20px;
    }

    .commentsheader{
        font-weight: 400;
        font-size: 20px;
        margin-bottom:5px;
    }

    .commentstitle{
        font-weight: 400;
        font-size: 12px;
        margin-bottom: 5px;

    }

    .textboxstyle{
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        height: 230px;
        margin-top: 0px;
        margin-bottom: 0px;
        border: none;
        outline: none;
        
        text-overflow: ellipsis;
        overflow-y: scroll;
        resize: none;
        background: #F7F9FC;
    }

    .cancelbuttonStyle{
        background: #ffffff;
        color: black;
        border: 1px solid #42D6C9;
        font-size: 14px;
        font-weight: 500;
    }

    .savebuttonStyle{
        background: #42D6C9;
        color: black;
        border: 1px solid #42D6C9;
        font-size: 14px;
        font-weight: 500;
    }

    .noti_container{
        height:300px;
    }





}