.storeSection {
    transition: height 3s ease;
    transition-delay: .4s;

    .store_font {
        color: #043854;
        position: relative;
        top: -10px;
    }

    .storeDetails {
        background-color: #fff;
        box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        transition: height 3s ease;
        transition-delay: .4s;
        padding: 0 10px;
        text-align: left;

        .businessname {
            position: relative;
            //border-bottom: 1px solid #ddd;

            .name {
                font-weight: 500;
                margin-bottom: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 250px;
                display: inline-block;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .selection {
                font-weight: normal;
                font-size: 14px;
                position: relative;
                bottom: 15px;
                margin-left: 4px;
                display: none;

            }
        }

        .storeInfo {
            margin-left: 0;
            margin-right: 0;

            .email {
                font-size: 14px;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 8px;
            }
        }

    }
}

.storeStyle {
    border: 1px solid #DBE2ED;
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 0px;
    padding: 13px;
    // margin-right: 70px;
    margin-top: 15px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);

    .no-store-row {
        padding-left: 0;
        padding-right: 0;

        .col {
            padding-left: 0;
            padding-right: 0;

            .store-btn {
                background: #42D6C9;
                border-radius: 20px;
                color: #043854;
                border: none;
                padding: 5px 5px;
                font-size: 14px;
                width: 150px;

                &:hover {
                    cursor: pointer;
                    border: 1px solid #42D6C9;
                }
            }

            .no-stores {
                font-size: 14px;

            }
        }
    }


    &.noBorderBottom {
        border-bottom: none;

        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .storeImage {
        height: 35px;
    }

    .storeTextwrapper {
        margin-top: 5px;
        margin-left: 0;
        margin-right: 0;
        padding-left: 15px;
        padding-right: 0;
        text-align: left;
        position: relative;

        .store_type {
            position: absolute;
            bottom: 0px;
            font-size: 11px;
            left: 45px;
            color: #7496A8;

        }

        .storeText {
            text-align: left;
            margin-left: 10px;
            font-weight: 400;
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 190px;
        }
    }

    .storeExpand {
        display: inline-block;
        cursor: pointer;
    }
}

.searchstoreWrapper {
    position: relative;
    margin-bottom: 15px;

    .searchstore {
        width: 100%;
        height: 40px;
        display: inline-block;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;
        margin-bottom: 0px;
        padding: 13px;
        margin-right: 70px;
        margin-top: 15px;
        // width: 80%;
    }

    .fail_req {
        font-size: 14px;
        margin-top: 10px;
        color: #043854;
    }

    .searchImage {

        position: absolute;
        float: right;
        top: 23px;
        right: 20px;
        cursor: pointer;
    }
}

.customerdetailsCard {
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 16px;

    .customerdetailsCardheader {
        padding: 18px;
        background: #FFFFFF;
    }

    .customerdeatilsheadertext {
        margin-bottom: 0px;
        text-align: left;
        font-weight: 500;
    }

    .customerdetailsbutton {
        float: right;
        margin-bottom: 0px;
        padding: 0px;
    }
}

.customerdetailsintro {

    .customerdetails {
        padding-left: 25px;
    }

    .customername {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .phone {
        font-size: 14px;
        margin-bottom: 8px;
        text-align: left;
    }

    .email {
        font-size: 14px;
        margin-bottom: 8px;
        text-align: left;
        word-wrap: break-word;
        word-break: break-word;
    }
}

.orderdetailsCard {
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 16px;

    .card-body {
        padding: 0;
    }

    .orderdetailsCardheader {
        padding: 18px;
        background: #FFFFFF;
    }

    .orderdeatilsheadertext {
        margin-bottom: 0px;
        text-align: left;
        font-weight: 500;
    }

    .orderdetailsbutton {
        float: right;
        margin-bottom: 0px;
        padding: 0px;
    }
}

.comment-item {
    font-size: 14px;
    margin-left: 10px;
    margin-top: 10px;
}

.orderdetailsintro {
    padding-left: 10px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    padding-top: 20px;

    .orderid {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .item {
        font-size: 14px;
        margin-bottom: 8px;
        text-align: left;
    }

    .qunatity {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .finalprice {

        font-size: 14px;
        margin-bottom: 8px;
    }

    .orderdate {
        font-size: 14px;
        margin-bottom: 8px;

    }

    .orderstatus {
        font-size: 14px;
        margin-bottom: 8px;

    }
}

.updateorderstatus {
    border-bottom: 1px solid #ddd;
    border-bottom: none;
    width: 100%;
    margin-left: 0;
    margin-right: 0;


    .orderstatuscol {
        padding-right: 0;
        padding-left: 0;
        padding-top: 10px;
        margin-right: 5px;
        margin-bottom: 10px;
    }

    .cancelorder {
        color: black;
        font-size: 14px;
        margin-bottom: 8px;
    }

    .orderstatusbutton {
        padding: 10px;
        background: #FFFFFF;
        /* Brand/ Color 2 */
        color: black;
        border: 1px solid #42D6C9;
        border-radius: 100px;
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 10 px;
    }

    .cancelorderbutton {
        padding: 10px;
        background: #FFFFFF;
        /* Brand/ Color 2 */
        color: black;
        border: 1px solid #DBE2ED;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 600;
    }
}



.paymentdetailsCard {
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 16px;

    .paymentdetailsCardheader {
        padding: 18px;
        background: #FFFFFF;
    }

    .paymentdeatilsheadertext {
        margin-bottom: 0px;
        text-align: left;
        font-weight: 500;
    }

    .paymentdetailsbutton {
        float: right;
        margin-bottom: 0px;
        padding: 0px;
    }
}

.paymentdetailsintro {


    margin-left: 15px;



    .paymentid {
        font-size: 14px;
        margin-bottom: 8px;

    }

    .paymentmethod {

        font-size: 14px;
        margin-bottom: 8px;
    }

    .paymentmethod {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .paymentdate {

        font-size: 14px;
        margin-bottom: 8px;
    }

    .paymentstatus {
        font-size: 14px;
        margin-bottom: 8px;

    }


}


.shipmentdetailsCard {
    box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12);
    border-radius: 8px;
    background: #FFFFFF;
    margin-bottom: 16px;

    .shipmentdetailsCardheader {
        padding: 18px;
        background: #FFFFFF;
    }

    .shipmentdeatilsheadertext {
        margin-bottom: 0px;
        text-align: left;
        font-weight: 500;
    }

    .shipmentdetailsbutton {
        float: right;
        margin-bottom: 0px;
        padding: 0px;
    }
}

.shipmentdetailsintro {


    margin-left: -3px;
    text-align: left;



    .address {
        font-size: 14px;
        margin-bottom: 8px;

        .addresstext {
            color: #8292AA;
        }

    }

    .city {

        font-size: 14px;
        margin-bottom: 8px;

        .citytext {
            color: #8292AA;
        }
    }

    .state {
        font-size: 14px;
        margin-bottom: 8px;

        .statetext {
            color: #8292AA;
        }
    }

    .country {

        font-size: 14px;
        margin-bottom: 8px;

        .countrytext {
            color: #8292AA;
        }
    }

    .pincode {

        font-size: 14px;
        margin-bottom: 8px;

        .codetext {
            color: #8292AA;
        }
    }

    .imojoshipping {

        font-size: 14px;
        margin-bottom: 8px;

        .imojoshippingtext {
            color: #8292AA;
        }
    }

    .waybill {

        font-size: 14px;
        margin-bottom: 8px;

        .waybilltext {
            color: #8292AA;
        }
    }

    .courierpartner {
        font-size: 14px;
        margin-bottom: 8px;

        .courierpartnertext {
            color: #8292AA;
        }

    }

    .trackingURL {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .trackingurlbutton {
        background: #FFFFFF;
        /* Brand/ Color 2 */
        color: black;
        border: 1px solid #42D6C9;
        border-radius: 100px;
        font-size: 12px;
        font-weight: 600;
    }


}

.orderStatusWrapper {

    .noti_container {
        min-height: 300px;

        .comments {}
    }

    .statusDropdown {
        button.btn {
            min-width: 170px;
        }

    }
}

.nopadding {
    padding-left: 0;
    padding-right: 0;
}

.nomargin {
    margin-right: 0;
    margin-left: 0;
}

.disabled {
    pointer-events: none;
    cursor: not-allowed;
}