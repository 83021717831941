.whatsappWrapper {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;

    .bottomTextarea {
        //width: 500px;
        bottom: 0;
        padding: 0;
        //background: #F7F9FC;
        border: 1px solid #ebeef0;
        box-sizing: border-box;

        //border-radius: 16px;
        margin: 15px;
        //display: grid;
        grid-template-columns: 1fr 20px;

        .header {
            width: 100%;
            height: 20px;
            background: #F7F9FC;

        }

        textarea {
            border: none;
            outline: none;
            min-height: 20px;
            text-overflow: ellipsis;
            overflow-y: scroll;
            resize: none;
            //background: #F7F9FC;
            width: 100%;
            padding: 5px 10px 0;
        }

        .bellIcon {
            position: absolute;
            // top: 15px;
            z-index: 9;
            cursor: pointer;
            right: 25px;
            bottom: 15px;
        }
    }

    .wa_conversations {
        overflow-y: scroll;
        height: calc(100vh - 240px);

        .sender {
            // margin-left: 64px;
            position: relative;
            //box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, .12);
            border-radius: 2px;
            padding-left: 8px;

            .time-style {
                color: #8292AA;
                font-size: 10px;
                padding-bottom: 5px;
                display: flex;
                margin-left: 50px;
            }

            .convElement {
                max-width: 50%;
                margin-bottom: 5px;

                .convReply {
                    border-top: solid 1px #C0CBDA;

                    .replyBtn {
                        background-color: #42D6C9;
                        color: #fff;
                        border-radius: 100px;
                        border: none;
                        margin-top: 5px;
                        padding: 5px 20px;
                        font-size: 14px;
                        font-weight: 500;

                        .replyIcon {
                            color: #fff;
                            margin-right: 6px;
                            font-size: 14px;
                        }
                    }
                }

                .senderStyle {
                    background-color: #edf1f7;
                    text-align: left;
                    width: fit-content;
                    padding: 5px 8px;
                    overflow-wrap: break-word;
                    box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    border-radius: 0px 8px 8px 8px;
                    font-size: 14px;
                    margin-left: 57px;



                    img {
                        max-width: 350px;
                        object-fit: contain;
                    }
                }
            }
        }

        .receiver {
            // margin-left: 64px;

            .userProfile {
                display: flex;
                flex-direction: row;
                padding: 0px;
                text-align: left;
                align-items: center;
                margin-left: 10px;
                margin-top: 10px;

                .initialsCircle {
                    border-radius: 50%;
                    width: 36px;
                    height: 36px;
                    background-color: #F7F9FC;
                    color: #043854;
                    display: inline-block;
                    text-align: center;
                    padding-top: 6px;
                    font-size: 14px;

                    &.owner {
                        background-color: #42D6C9;
                    }
                }
            }

            position: relative;
            border-radius: 2px;
            padding-left: 8px;

            .timeStamp {
                color: #8292AA;
                font-size: 10px;
                padding-bottom: 5px;
                display: flex;
                margin-left: 50px;
            }


            .receiverStyle {
                text-align: left;
                width: fit-content;
                max-width: 70%;
                padding: 5px 8px;
                overflow-wrap: break-word;
                border-radius: 0px 8px 8px 8px;
                background-color: #FFFAE5;
                font-size: 14px;
                margin-left: 50px;
                margin-bottom: 5px;

            }

        }


    }
}