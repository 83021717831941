.emailSetup {
    text-align: left;
    margin-top: 10px;

    .email_Setup_h5 {
        margin-bottom: 0;

    }

    .backdiv {
        margin-bottom: 20px;
        cursor: pointer;

        .backIcon {
            margin-right: 5px;
        }
    }

    .emailProcess {
        box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px;
        margin-top: 25px;
        background: #fff;
        position: relative;

        .mailbox-settings {
            padding-top: 20px;
            position: relative;

            .senderInput {
                color: #000;
            }

            .mail-status {
                position: absolute;
                right: 40%;
            }

            .save-mailbox-settings {
                margin-top: 40px;
                border-radius: 5px;
                color: #0D1220;
                font-weight: 500;
                border: #42D6C9;
                padding: 1px 10px;
                background: #42D6C9;
                width: 100px;
                height: 40px;
            }

            .sender-name-label {
                font-size: 16px;
                font-weight: 400;
                color: #0D1220;
                margin-top: 15px;
                margin-bottom: 0px;

                &.small {
                    font-size: 12px;
                    margin-top: 2px;
                    margin-bottom: 10px;
                }
            }
        }

        .verified-header-div {
            border-bottom: 1px solid #ddd;
            margin-bottom: 15px;

            .verified-header {
                font-size: 18px;
                margin-bottom: 9px;

                .verified-checkIcon {
                    color: #42D6C9;
                    margin-right: 10px;
                }
            }
        }

        .ch-details {
            font-size: 16px;
            font-weight: bold;


        }

        .email-not-approved {
            padding-top: 20px;
            display: inline-block;

            .info {
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        .toaster {
            position: absolute;
            left: 60%;
            z-index: 10;
        }

        .emailInput {
            background: #FFFFFF;
            border: 1px solid #DBE2ED;
            box-sizing: border-box;
            border-radius: 8px;
            height: 54px;
        }

        .step2Heading {
            font-size: 20px;
            font-weight: 500;
            font-size: 20px;
            color: #0D1220;
            mix-blend-mode: normal;

        }

        .step2note {
            font-size: 16px;
            font-weight: 400;
            color: #0D1220;
            mix-blend-mode: normal;
            margin-top: 30px;
            box-sizing: border-box;
            border-radius: 8px;
            background: #FFFFFF;
            border: 1px solid #DBE2ED;
            padding: 20px;
            background-color: #F3ECEC;
            display: inline-block;

            .note {
                margin-bottom: 4px;
                font-size: 15px;
            }

        }

        .step2Description {
            font-size: 16px;
            font-weight: 400;
            color: #0D1220;
            mix-blend-mode: normal;
            margin-top: 15px;
            margin-bottom: 4px;

            &.last {
                margin-top: 0;
            }
        }

        .copy-btn {
            display: block;
            border: none;
            margin-top: 10px;
            padding: 5px 21px;
            border-radius: 10px;
        }

        .emailverify-error {
            position: absolute;
            position: absolute;
            right: 20%;
        }

        .copyEmailDiv {
            width: 500px;
            padding: 5px 15px;
            border: 1px solid #DBE2ED;
            box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.07);
            border-radius: 8px;
            background-color: #F7F9FC;
            margin-left: 0px;
            margin-top: 30px;

            .email_infor {
                font-size: 16px;
                margin-bottom: 0;
            }

            .copyCol {
                border-left: 1px solid #ddd;
                text-align: center;

                .copyIcon {
                    font-size: 25px;
                    color: #C0CBDA;
                    cursor: pointer;
                }
            }
        }

        input.form-control {
            max-width: 500px;
        }

        .forward-added {
            margin-top: 20px;
        }

        .verifyBtn {
            margin-top: 10px;
            border-radius: 100px;
            color: #0D1220;
            font-weight: 500;
            border: #42D6C9;
            padding: 8px 25px;
            background: #42D6C9;
            width: 170px;
            height: 50px;
            display: block;

            .loader {
                position: relative;
                bottom: 3px;
                right: 5px;
            }

            &.disabled {
                background-color: #ddd;
                pointer-events: none;
            }
        }

        .nextBtn {
            margin-top: 40px;
            border-radius: 100px;
            color: #0D1220;
            font-weight: 500;
            border: #42D6C9;
            padding: 8px 25px;
            background: #42D6C9;
            width: 143px;
            height: 45px;

            &.disabled {
                background-color: #FFFFFF;
                pointer-events: none;
                border: 1px solid #0D1220;
                border-radius: 100px;
            }
        }

        .cancelBtn {
            margin-top: 10px;
            background: transparent;
            border-radius: 100px;
            color: #0D1220;
            border: none;
            padding: 8px 25px;
            border: 1px solid #0D1220;
        }
    }
}