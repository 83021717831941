.ticketsWrapper {
    .list {
        height: calc(100vh - 190px);
        overflow-y: scroll;
        scrollbar-width: none;

        .create_new_ticket {
            padding: 4px 8px;
            width: 134px;
            height: 34px;
            border: 1px solid #043854;
            border-radius: 20px;
            background: #FFFFFF;
            font-size: 13px;
            color: #043854;
            margin: 5px;
            margin-top: 10px;
        }

        .create_new_ticket:hover {
            border: 1px solid #42D6C9;
            color: #043854;
            background: #42D6C9;

        }



        .no_tickets {
            margin-top: 20px;
        }

        .activeTicket {
            background-color: #F7F9FC;
            border-left: 5px solid #18A0FB;
        }

        .ticketsCol {
            margin-left: 0;
            margin-right: 0;
        }

        .ticket {
            padding: 24px 0px;
            text-align: left;
            font-size: 14px;
            border-bottom: 1px solid rgb(235, 238, 240);
            cursor: pointer;
            position: relative;

            &.closed-border {
                border-left: 4px solid #F69195;
            }

            .closed-tick {
                font-size: 11px;
                display: inline-block;
                padding: 1px 2px;
                border-radius: 4px;
                border: 1px solid #F69195;
                color: #A40215;
                position: absolute;
                right: 10px;
                bottom: 4px;
            }

            &:hover {
                border-bottom: 3px solid rgb(235, 238, 240);
                font-size: 15px;
            }

            .unreadSpan {
                position: absolute;
                right: 0px;
                top: 10px;
                background-color: #42D6C9;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin-right: 4px;
                margin-left: 5px;
            }

            .readSpan {
                position: absolute;
                right: 0px;
                top: 10px;
                margin-left: 5px;
                margin-right: 4px;
                background-color: #8292AA;
                width: 11px;
                height: 11px;
                border-radius: 50%;
            }
            
            .star-icon {
                position: absolute;
                right: 25px;
                top: 4px;

                svg {
                    width: 13px;
                }

                .favourite-star {
                    color: #42D6C9;
                }

                .not-favourite-star {
                    color: #8292AA;
                }
            }

            .senderImg {
                width: 35px;
                height: 35px;
                border-radius: 50%;
            }

            .timestamp {
                font-size: 10px;
                padding-left: 0;
                padding-right: 1px;
                color: #8292AA;
            }

            .senderName {
                font-size: 14px;
                font-weight: 600;

                .name {
                    width: 190px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .messagetext {
                margin-left: 0px;
                font-weight: 400;
                color: #8292AA;
                font-size: 12px;
                width: 245px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;

                &.emailSubject {}
            }
        }
    }

    .list::-webkit-scrollbar {
        display: none;
    }
}

.customTicketModalWrapper {

    .ticket-details {
        height: 250px;

        .quill {
            height: 60%;
        }
    }

    .ticket-error {
        color: red;
        font-size: 14px;
        margin-left: 10px;
        display: inline-block;
    }

    .modalTitle {
        font-weight: 500;
        font-size: 24px;
        color: #0D1220;
        mix-blend-mode: normal;

    }

    .inputBox {
        width: 460px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;

    }

    .textBox {
        width: 460px;
        height: 200px;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;

    }




    .submit_btn {
        width: 132px;
        height: 40px;
        background: #42D6C9;
        border-radius: 100px;
        border: #42D6C9;
        color: black;

    }
}

.ticket_box {
    text-align: left;
    padding: 10px;
    background: #F7F9FC;
    border-radius: 0px 8px 8px 8px;
    width: 584px;

    .ticket_header {
        font-weight: 500;
        font-size: 14px;
        border-bottom: 1px solid #DBE2ED;

    }

    .ticket_body {
        font-size: 14px;

    }



}

.timeStyle {
    margin-top: 13px;
    margin-right: 10px;
}