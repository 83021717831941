.notificationWrapper {
    position: relative;

    .modal-title {
        flex: 1 100px;
    }



    .enablePushWrapper {
        box-shadow: 0px 14px 64px -4px rgba(24, 39, 75, 0.12);
        border-radius: 20px;
        padding: 18px;
        margin-bottom: 25px;
        position: absolute;
        top: 10px;
        margin: 0 auto;
        left: 0;
        right: 0;
        width: 500px;
        background-color: #fff;
        z-index: 2;

        .noti_remove {
            position: absolute;
            right: 15px;
            top: 12px;

            &:hover {
                cursor: pointer;
                color: #000;
            }
        }


        .push_heading {
            color: #0D1220;
            font-size: 15px;
            font-weight: 400;
        }

        .push_content {
            font-size: 13px;
            color: #0D1220;
        }

        .enableBtn {
            border: 1px solid #94F4C9;
            padding: 2px 20px;
            border-radius: 50px;
            font-size: 14px;
            background-color: #fff;
        }
    }
}






.noPadding {
    padding-left: 0;
    padding-right: 0;
}



.noti_header {

    .headline {
        font-weight: 500;
        font-size: 25px;
        color: #0D1220;
    }

    .unread {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        background: #F9C933;
        border-radius: 8px;
        margin-left: 5px;
    }
}

.clear_all_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    width: 133px;
    height: 40px;
    background-color: #EEF3F8;
    border-radius: 100px;
    color: #0D1220;
    font-weight: 500;
    border-color: #EEF3F8;
    font-size: 12px;
}

.clear_all_btn:hover {
    background-color: #42D6C9;
    border: #42D6C9
}





.noti_container {
    overflow-y: scroll;
    height: 500px;

    .day_class {
        font-weight: normal;
        font-size: 14px;
        color: #526179;
        mix-blend-mode: normal;
        margin-top: 10px;
    }

    .noti_band {
        margin-bottom: 10px;
        padding: 10px;

        .noti_col {
            padding-left: 0;
            padding-right: 0;

            .noti_type {
                color: #8292AA;
                display: inline-block;
                width: 34px;
                text-align: center;
            }

            .noti_time {
                font-size: 12px;
                font-weight: normal;
                text-align: right;
                color: #8292AA;
                mix-blend-mode: normal;

            }
        }
    }

    .noti_band:hover {
        background: #F7F9FC;
        border-radius: 8px;
        cursor: pointer;
    }


    .user_name {
        font-weight: 500;
        font-size: 14px;
        color: #043854;
        mix-blend-mode: normal;
        margin-right: 10px;
    }

}

.load_btn {
    padding: 10px 20px;
    width: 128px;
    height: 44px;
    background: #42D6C9;
    border-radius: 100px;
    color: #0D1220;
    border: #42D6C9;
    font-weight: 500;
    margin: 0 auto;
    display: block;
}

.hide {
    display: none;
}