.createEmailWrapper {
    width: 100%;
    position: relative;
    box-shadow: 0px 8px 33px 8px rgba(24, 39, 75, 0.09);
    border-radius: 10px;
    overflow-y: scroll;
    /* width: 100%; */
    background-color: #fff;
    padding: 10px;
    text-align: left;
    scrollbar-width: 0px;

    &::-webkit-scrollbar {
        display: none;
    }

    .createEmailModalClose {
        position: absolute;
        top: 10px;
        right: 15px;
        padding: 5px;
        font-size: 16px;
        cursor: pointer;
    }

    .emailHeader {
        font-size: 14px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;

        .info {
            display: inline-block;
            margin-right: 2px;
            position: relative;

            &.width {
                width: 70px;
            }
            &.reply-to-container {
                width: 90%;
                max-width: 550px;

                .cc-bcc-holder {
                    position: absolute;
                    top: 8px;
                    right: -13px;
                    display: flex;
                    width: 55px;
                    z-index: 2;
                    cursor: pointer;
    
                    div:hover {
                        text-decoration: underline;
                    }
                    .bcc-option {
                        margin-left: 8px;
                    }
                }
                .react-multi-email {
                    margin-left: 12px;
                    margin-bottom: 8px;
                    width: calc(100% - 60px) !important;
                }
            }
        }

        .initials {
            background: rgb(229, 229, 229);
            width: 30px;
            height: 30px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 500px;
            font-size: 12px;
            font-weight: bold;
            position: relative;
            top: 5px;
        }

        .sendingInfo {
            position: relative;

            .option_email {
                position: absolute;
                right: 10px;
                border: 1px solid #ddd;
                cursor: pointer;
                display: none;
            }
        }

        .to_field {
            width: 80%;
            display: inline-block;
            position: relative;

            &.reply-cc-container {
                margin-left: 10px !important;
                max-width: 450px;
                
                .cc-bcc-close-button {
                    right: 20px;
                }
            }
            
            &.reply-bcc-container {
                margin-left: 5px !important;
                max-width: 450px;

                .cc-bcc-close-button {
                    right: 20px;
                }
            }

            .cc-bcc-close-button {
                position: absolute;
                top: 50%;
                right: 35px;
                transform: translateY(-50%);
                cursor: pointer;
                padding: 5px;
                font-size: 16px;
            }

            .cc-bcc-holder {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: calc(-10px);
                display: flex;
                width: 55px;
                // justify-content: flexs;
                z-index: 2;
                cursor: pointer;

                div:hover {
                    text-decoration: underline;
                }
                .bcc-option {
                    margin-left: 10px;
                }
            }

            .react-multi-email {
                width: 90%;
                cursor: text;
            }

            input {
                width: 90%;
            }
        }
    }

    .envelopeExpand {
        position: absolute;
        right: 12px;
        top: 12px;
        cursor: pointer;

        svg {
            color: #8292AA
        }
    }

    .replyEmailContent {
        width: 100%;
        min-height: 380px;
        padding-bottom: 5px;

        .email-last-row-container {
            display: flex;
            align-items: center;
            margin-top: 25px;

            button {
                position: relative;
                top: 0px;
            }

            .attachment-holder {
                position: relative;
                left: 12px;
                cursor: pointer;
                transition: all 0.2s;
                border-radius: 3px;
                font-size: 15px;
                
                &:hover {
                    background-color: #e9e9e9;
                }
                
                .dropzone {
                    padding: 5px 10px;
    
                    .attach-icon {
                        margin-right: 5px;
                    }
                }
            }
        }

        .ql-editor {
            min-height: 20em;
            border: none;
        }

        .editorStyles {
            box-sizing: border-box;
            //border: 1px solid #ddd;
            cursor: text;
            //padding: 16px;
            border-radius: 2px;
            margin-bottom: 1em;
            background: #fefefe;

            &.replyStyles {
                border: none;
                padding: 0px;
            }
        }

        .editorStyles .public-DraftEditor-content {
            min-height: 250px;
            overflow-y: scroll;

        }

        .each-attached-file {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 500px;
            background-color: #f1f1f1;
            padding: 0px 10px;
            margin-bottom: 5px;

            .attached-file-name {
                font-size: 14px;
            }

            .remove-file-button {
                padding: 4px;
                font-size: 15px;
                color: #494949;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .cc-bcc-holder {
        right: -25px !important;
    }
    .cc-bcc-close-button {
        right: 15px !important;
    }
}