
.ProfiledetailsWrapper{
    overflow: auto;

.profileWrapperHeaderTitle{
        font-weight: 500;
        font-size: 20px;
        color:#0D1220;
        text-align: left;
        margin-top: 15px;
        margin-bottom: 15px;
}




    .profileheadertitle{
        font-weight: normal;
        font-size: 12px;
        color:#526179;
        text-align: left;
        margin-top: 10px;
        margin-bottom: 10px;

    }

    .first_name{
        width: 400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;

    }

    .last_name{
        width: 400px;
        height: 60px;
        background: #FFFFFF;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .role_info{
        background: #EEF3F8;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        pointer-events: none;
        text-transform: capitalize;
    }

    .email_id{
        width: 400px;
        height: 60px;
        background: #EEF3F8;
        border: 1px solid #DBE2ED;
        box-sizing: border-box;
        border-radius: 8px;
    }

    .update_profile_btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        width: 128px;
        height: 44px;
        background: #42D6C9;
        border-radius: 100px;
        color:#0D1220;
        border:#42D6C9;
        font-weight:500;
    }
    
}
