.parent-sections{
    .nav-section{
        display: block;
    }

    .dashboard {
        position: relative;
        height: 760px;
        overflow-y: auto;
        background: #F7F9FC;
        display: grid;
        grid-template-columns: 195px 270px 1fr 310px;

        &.settingActive {
            grid-template-columns: 175px 1fr;
        }

        .settingsCol {
            height: 100vh;
        }

        .sidebar {
            padding-left: 0;
            padding-right: 0;
            height: calc(100vh - 55px);
            background: #043854;
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
            transition-duration: .2s, .2s, .35s;
            transition-property: top, bottom, width;
            transition-timing-function: linear, linear, ease;
            z-index: 9;
        }
        &.rightSidebarClosed {
            grid-template-columns: 195px 270px 1fr;
        }

        .tickets {
            position: relative;
            padding-left: 0;
            padding-right: 0;
            background-color: #fff;
            border-right: 1px solid #DBE2ED;

            .ticket-loader{
                margin-top: 10px;
            }
        }

        .rightSidebarstyle {
            padding: 12px;
            background-color: #F7F9FC;
            overflow-y: scroll;
            height: 100vh;
            padding-top: 0px;
            overflow-x: visible;
            position: relative;
        }

        .platformListBar {
            border-right: 1px solid #ddd;
            position: relative;
            // left: 70px;
            padding-left: 0;
            padding-right: 0;
        }

        .content {
            padding-left: 0;
            padding-right: 0;
            border-right: 1px solid #DBE2ED;
            overflow-x: auto;
            // width: calc(100% - 70px);
            background-color: #FFFFFF;
            // height: 100vh;
            position: relative;

            .rightNavbarToggleBtn {
                position: absolute;
                top: 80px;
                right: 0px;
                height: 25px;
                width: 30px;
                background-color: #DBE2ED;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.308);
                z-index: 5;
                cursor: pointer;

                .toggle-icon {
                    font-weight: bold;
                    font-size: 15px;
                }
            }
        }
    }
    .mobile-info{
        display: none;
    }
    @media screen and (max-width:520px) {
        .dashboard {
            display: none !important;
        }
        .nav-section{
            display: none;
        }
        .mobile-info{
            display: grid;
            justify-content: center;
            min-height: 100vh;
            width: 100%;
            padding: 20px;

            .main-mbl-content{
                padding: 20px;
                padding-top: 50px;
                box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
            }
            
        }
    }
}

.hide {
    display: none;
}