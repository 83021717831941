.marketingWrapper {

    .marketingParent {
        // height: 760px;
        background: #F7F9FC;
        position: relative;
        display: grid;
        grid-template-columns: 190px 1fr;

        .whatsapp-campaigns {
            height: 100%;
            padding-bottom: 20px;
        }

        .whatsapp-marketing {
            padding: 10px 20px;
            text-align: left;
            height: 90%;

            .marketing-content-creation {
                height: 100%;

                .creation-buttons {
                    margin-top: 20px;
                    text-align: right;

                    .next-btn {
                        background-color: #fff;
                        color: #043854;
                        border-color: #043854;
                        width: 100px;
                        font-weight: bold;
                    }
                }

                .creation-content {
                    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
                    border-radius: 10px;
                    box-sizing: border-box;
                    padding: 10px 20px;
                    margin-top: 25px;
                    background: #fff;
                    height: calc(100% - 150px);

                    .stepone-wrapper {
                        height: 90%;

                        .campaign-name-div {
                            margin-bottom: 10px;
                            display: none;

                            .campaign-name {
                                background: #F7F9FC;
                                border: 1px solid #EEF3F8;
                                box-sizing: border-box;
                                border-radius: 8px;
                                height: 40px;
                                width: 300px;

                                &::placeholder {
                                    padding-left: 20px;
                                    font-size: 15px;
                                    color: #888;
                                }
                            }
                        }

                        .creation-info {
                            font-size: 12px;
                            color: #888;
                            cursor: pointer;

                            position: relative;
                            left: 10px;
                            bottom: 2px;
                        }
                    }

                    .heading {
                        font-size: 20px;
                        font-weight: 500;
                        font-size: 20px;
                        color: #0D1220;
                        mix-blend-mode: normal;
                    }
                }
            }

            .top-panel {
                display: grid;
                grid-template-columns: 1fr 200px;
                margin-bottom: 10px;

                .createBtn {
                    font-size: 14px;
                    border-radius: 100px;
                    border: none;
                    color: #043854;
                    font-weight: 500;
                    background: #42D6C9;

                    &:hover {
                        background-color: #EEF3F8;
                    }
                }

                .header-text {
                    font-size: 20px;
                    margin-bottom: 0;
                    color: #043854;

                    .horn {
                        margin-left: 5px;
                    }
                }
            }

            .whatsapp-body {
                background: #FFFFFF;
                min-height: 200px;
                border: 1px solid #EEF3F8;
                box-sizing: border-box;
                border-radius: 8px;
                padding: 20px;

                &.empty {
                    text-align: center;

                    .empty-case {
                        padding: 50px;

                        .text-empty {
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }

        .sidebar {
            padding-left: 0;
            padding-right: 0;
            background: #043854;
            box-shadow: 0px 6px 14px -6px rgba(24, 39, 75, 0.12);
            transition-duration: .2s, .2s, .35s;
            transition-property: top, bottom, width;
            transition-timing-function: linear, linear, ease;
            z-index: 9;
        }
    }
}

.whatsappCreateWrapper {}