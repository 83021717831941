.whatsapp-step-one {
    height: 90%;

    .templates-top-panel {
        display: grid;
        grid-template-columns: 300px 200px;

        .search-templates-input {
            background: #F7F9FC;
            border: 1px solid #EEF3F8;
            box-sizing: border-box;
            border-radius: 8px;
            height: 40px;
            width: 100%;

            &::placeholder {
                padding-left: 20px;
                font-size: 15px;
                color: #888;
            }
        }
    }

    .content-panel {
        margin-top: 2px;
        display: grid;
        grid-template-columns: 1fr 400px;
        grid-column-gap: 20px;
        height: calc(100% - 50px);

        .templates-preview {
            margin: 20px 0 0;
            max-height: calc(100vh - 400px);
            background-color: #E6EBEC;
            padding: 20px 10px;

            .preview-wrapper {
                background-color: #fff;
                box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
                word-wrap: break-word;
                border-radius: 4px;
                width: 270px;
                margin: 0 auto;
                padding: 20px 10px;

                .header {}

                .body-text {
                    margin-top: 10px;
                }
            }
        }

        .templates-container {
            max-height: calc(100vh - 400px);
            padding: 10px 0 0;

            .templates-div {
                height: calc(100% - 20px);
                overflow: auto;

                .template-content {
                    margin: 10px 2px;
                    border: 1px solid #ddd;
                    padding: 10px;

                }

            }
        }
    }
}