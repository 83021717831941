.billingWrapper {
    text-align: left;
    padding-top: 20px;

    
    .billingTitle {}

    .plan-name {
        display: inline-block;
        font-weight: bold;
    }
}

.hide {
    display: none;
}

.billing-alert {
    display: flex;
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #A03F00;
    background-color: #FFFAE5;

    .warn-icon-container {
        margin-right: 1em;

        svg {
            color: #F9C933;
        }
    }

    h5 {
        font-size: 19px;
    }

    p {
        margin-bottom: 0px;
    }
}

.card {
    border: 1px solid #EEF3F8;
    border-radius: 7px;
    box-shadow: 0px 4px 6px -4px #18274B1F;
}

.billing-plan-card {
    margin-bottom: 30px;

    .annual-billing-container {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 20px;
        justify-content: flex-end;

        &.hide {
            display: none;
        }

        #annual-checkbox {
            opacity: 0;
            height: 0;
            width: 0;
        }

        label {
            margin-right: 80px;
        }

        .checkbox-bg {
            width: 65px;
            height: 32px;
            border-radius: 25px;
            background-color: #C0CBDA;
            position: absolute;
            top: 0px;
            right: 210px;
            transition: all 0.3s;
            cursor: pointer;
        }

        .checkbox-bg::after {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0px 6px 14px -6px #18274B1F;
            transition: all 0.4s;
        }

        #annual-checkbox:checked+.checkbox-bg {
            background-color: #2BAE6B;
        }

        #annual-checkbox:checked+.checkbox-bg::after {
            position: absolute;
            left: calc(100% - 2px);
            transform: translateX(-100%);
        }
    }

    .card-footer {
        background-color: #F7F9FC;
        border-top: 0px;

        .coupon-alert{
            display: inline-block;
            width: 600px;
            position: relative;

            .coupon-info{
                font-size: 15px;
                
            }
        }
        .coupon-container {
            position: relative;
            // display: inline-block;
            display: none;

            svg {
                position: absolute;
                top: 50%;
                left: 15px;
                transform: translateY(-50%);
                color: #043854;
            }

            input {
                padding-left: 40px;
                border-radius: 10px;
                padding-right: 30px;
                height: 43px;
                border: 1px solid #DBE2ED;
                outline: none;

                &::placeholder {
                    font-size: 14px;
                }
            }

            button {
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                outline: none;
                transform: translateX(-25px);
                border: none;
                width: 80px;
                height: 43px;
                background-color: #043854;
                color: white;
            }
        }
    }
}

.billing-pricing-table {
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    border-radius: 5px;
    margin-top: 20px;

    .billing-details-info {

        .subs-status-pending {
            color: #EE8B00;
        }
    }

    .row .col {
        padding-left: 20px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .row:first-child .col {
        padding-top: 7px;
        padding-bottom: 7px;
        font-weight: 600;
        font-size: 17px;
        color: #526179;
    }

    .row:not(:first-child) .col {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        display: flex;
        align-items: center;
        font-weight: 500;
    }

    .row:not(:first-child) .col:first-child {
        color: #526179;
        font-weight: 600;
    }

    .text-light-gray {
        color: #8292AA;
    }

    .plan-status {
        border-radius: 25px;
        padding: 4px 15px;
        display: inline;
        outline: none;
        border: none;
        font-weight: 500;
    }

    .plan-status.current {
        background-color: #d1fbe5;
        color: #103E2C;
    }

    .plan-status.upgrade {
        background-color: #043854;
        color: white;
    }

}

.billing-history-card {
    .card-header {
        background-color: white;
        border-bottom: 0px;
    }

    .card-body {
        padding: 0px;
    }

    .container-fluid {
        border-top: 1px solid #DBE2ED;
        border-bottom: 1px solid #DBE2ED;

        .row {
            padding-top: 8px;
            padding-bottom: 8px;

            .col:first-child {
                flex: 1;
            }

            .col:nth-child(2) {
                flex: 1.7;
            }

            .col:nth-child(3) {
                flex: 0.8;
            }

            .col:nth-child(4) {
                flex: 0.8;
            }

            .col:last-child {
                flex: 2;
            }
        }

        .row:first-child {
            background-color: #F7F9FC;
            font-weight: 500;
            color: #526179;
            flex: 1;
        }

        .row:not(:last-child) {
            border-bottom: 1px solid #DBE2ED;
        }

    }
}

.f-size-12 {
    font-size: 12px;
}

// responsiveness

@media screen and (max-width: 1100px) {
    .billing-history-card .container-fluid .row {
        .col {
            flex: 1;
        }

        .col:first-child {
            width: 15%;
        }

        .col:nth-child(2) {
            width: 25%;
        }

        .col:nth-child(3) {
            width: 15%;
        }

        .col:nth-child(4) {
            width: 15%;
        }

        .col:last-child {
            width: 30%;
        }
    }
}