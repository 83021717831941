.navWrapper {
    background: #FFFFFF;
    height: 55px;
    position: relative;
    z-index: 1;
    box-shadow: 0px 6px 15px -10px rgba(24, 39, 75, 0.12);
    display: grid;
    grid-template-columns: 200px 1fr 300px;

    .hide{
        display: none;
    }

    .nav-logo {
        padding: 15px 0;

        .logo-span{
            cursor: pointer;
        }
    }

    .nav-options-parent {
        text-align: left;

        .nav-option {
            display: inline-block;
            padding: 15px;
            margin-right: 10px;
            margin-left: 10px;
            color: #043854;
            font-weight: 400;
            height: 55px;
            font-size: 14px;

            &.active {
                border-bottom: 4px solid #42D6C9;
                color: #043854;
            }

            &:hover {
                cursor: pointer;
                color: #27292d;
            }
        }
    }

    .nav-right {
        padding: 15px 30px;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        .getting-started {
            margin-right: 20px;
            cursor: pointer;

            .start-gift {
                margin-right: 5px;
                color: #42D6C9;
            }
        }
        .bell-icon {
            margin-right: 15px;
            cursor: pointer;
            width: 22px;
        }

        .settings-icon {
            cursor: pointer;
            width: 22px;
        }
    }
}