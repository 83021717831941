.whatsapp_parent {
    text-align: left;

    .gobackdiv {
        margin-bottom: 20px;
        cursor: pointer;

        .backIcon {
            margin-right: 5px;
        }
    }

    .whatsapp_content {
        position: relative;

        .form-error {
            position: absolute;
            bottom: 2px;
            left: 10px;
            color: red;
            font-size: 15px;
            font-weight: bold;
        }

        .form-complete {
            position: absolute;
            bottom: 2px;
            left: 10px;
            color: #0D1220;
            font-size: 15px;
            font-weight: bold;
        }

        .stepper_next {
            text-align: right;

            .next_btn {
                background: #42D6C9;
                border-radius: 100px;
                color: #0D1220;
                border: none;
                padding: 10px 0px;
                width: 120px;
                margin-top: 15px;
                font-size: 16px;
            }
        }

        .stepper_content {
            box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
            border-radius: 10px;
            box-sizing: border-box;
            padding: 20px;
            margin-top: 25px;
            background: #fff;

            .step-one {

                .required {
                    font-size: 11px;
                }

                .stepper-form {
                    margin-bottom: 18px;

                    .col {
                        .info_icon {
                            color: #043854;
                            font-size: 13px;
                            display: inline-block;
                            margin-left: 5px;
                            cursor: pointer;
                        }

                        .title {
                            display: block;
                            font-size: 16px;
                            margin-bottom: 5px;
                        }

                        .step_form_input {
                            background: #F7F9FC;
                            border: 1px solid #EEF3F8;
                            box-sizing: border-box;
                            border-radius: 8px;
                            height: 48px;
                            padding: 0 4px;
                            width: 80%;
                        }
                    }

                }
            }
        }

        .whatsapp_stepper {
            box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
            border-radius: 10px;
            background-color: #fff;
            padding: 20px 30px;
            margin-top: 20px;
            margin-left: 0;
            margin-right: 0;

            .step_indicator {
                display: inline-block;
                border-bottom: 3px solid #ddd;
                height: 3px;
                position: relative;
                top: 12px;
            }

            .stepper_col {
                padding-right: 0;
                padding-left: 0;
                text-align: center;

                .checkIcon {
                    font-size: 18px;
                    color: #ddd;

                    &.success {
                        color: #42D6C9;
                    }
                }

                .text {
                    font-size: 18px;
                    display: inline-block;
                    margin-left: 8px;
                }
            }
        }
    }
}