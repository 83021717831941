
.headerComponent{
    
    .hide-lg{
        display: none;
    }

    .headerStyle{
        background: #E2F3F6;
        font-weight: 700;
        font-size: 25px;
    }
    .logo{
        margin-top: 50px;
        margin-left: 150px;
    }

    .logoStyle{
        height:40px;
    }

    .mezchip_logo{
        padding: 1px;
        font-weight: 500;
        font-size: 24px;
    }
}

@media screen and (max-width:770px) {
    .headerComponent{
        .hide-lg{
            display: auto;
        }
        .logo{
            margin-top: 5px;
            margin-left: 5px;
        }
        .mezLogo{
            display: none;
        }
    }
}