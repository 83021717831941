.platformsWrapper{
    .header{
        padding-top: 20px;
        padding-bottom: 0px;
        border-bottom: 1px solid #ebeef0;
        p{
            font-size: 22px;
        }

    }
}
