.signup_component {
    background-color: #E2F3F6;
    height: 100vh;

    .logoStyle {
        height: 100px;
    }

    .formData {
        .business-info {
            font-size: 11px;
            text-align: left;
        }

        .form-group {
            text-align: left;
        }

        .agree_checkbox {
            text-align: left;
            display: inline-block;
            font-size: 12px;
        }

        .terms_link {
            display: inline-block;
            font-size: 12px;
        }

        .alert {
            font-size: 14px;
        }
    }

    .create_account {
        font-weight: 600;
        font-size: 45px;
        color: #171F2D;
        text-align: left;

        padding-right: 30px;
        margin-top: 40px;
    }

    .loginText {
        margin-left: 0px;
        padding: 0px;
    }

    .row {
        height: 100%;
        margin-top: 2%;

        .contents {
            padding: 30px 40px;

            .input_form_signup{

                &::placeholder {
                    color: #ddd;
                }
            }

            .instamojo-info {
                color: #043854;
                font-size: 13px;
                font-weight: bold;
                text-align: left;
            }

            .already_acct {
                margin-top: 25px;
                text-align: left;
                font-size: 14px;
            }

            .forgotpass {
                text-align: left;
                margin-top: 10px;
            }

            .signup_btn {
                background-color: #42D6C9;
                border-radius: 100px;
                border: none;
                color: #0D1220;
                padding: 12px 25px;
                display: block;
                box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
            }

            .signup_loader {
                text-align: left;
                margin-top: 10px;
            }

            .smallHeaderNote {
                font-size: 14px;
                color: #526179;
                display: inline-block;
                text-align: left;
                width: 100%;
                margin-top: 10px;
            }

            .input_form {
                border: 1px solid #DBE2ED;
                box-sizing: border-box;
                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.07);
                border-radius: 8px;
                width: 400px;
                height: 60px;
                color: #0D1220;
            }
        }
    }

    .password_section {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .input_form {
        position: relative;
    }

    .hidden_password {
        font-size: 15px;
        margin-right: 10px;
        object-fit: cover;
        position: absolute;
        justify-content: flex-end;
        text-align: right;
        color: #42D6C9 !important;

        &:hover {
            font-size: 22px;
            margin-right: 10px;
            object-fit: cover;
            position: absolute;
            justify-content: flex-end;
            text-align: right;
            cursor: pointer;
            color: #207971 !important;
        }

        .input_form_login {
            padding: 20px 0 !important;
        }

        
    }
    .onlyMobile{
        display: none;
    }

}


@media screen and (max-width:520px) {
    .signup_component {
        -webkit-overflow-scrolling: touch;
        height: 680px;
        overflow-y: scroll;
        
        .mobile-info{
            font-size: 12px;
            padding: 5px;
        }
        .create_account{
            display: none;
        }
        .loginText{
            font-size: 7.6vw;
            width:85%;
            margin-left: auto;
            margin-right: auto;
        }
        .logoStyle{
            height:40px;
        }
        .loginText {
            display: none;
        }   
        .onlyMobile{
            display: block;
        }
        .row {
            .contents {
                padding: 10px 20px;

                .signup-error{
                    padding: 6px;
                    position: absolute;
                    top: 10px;
                }
                
                //https://codepen.io/finnhvman/post/pure-css-ripple-with-minimal-effort
                .ripple {
                    background-position: center;
                    transition: background 0.5s;
                }
                .ripple:hover {
                    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
                    background: #42D6C9 radial-gradient(circle, transparent 1%, #69E4A6 1%) center/15000%;
                }
                .ripple:active {
                    background-color: #42D6C9;
                    background-size: 100%;
                    transition: background 0s;
                    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
                }
            }
        }
    }
}