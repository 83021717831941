.settingsComtainer {
    background-color: #fff;
    padding: 20px 40px;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;

    h3 {
        text-align: left;
    }

    .settingsTab {

        .settings_tab_row {

            .tabRow {
                margin-left: 0;
                margin-right: 0;
                border-bottom: 1px solid #DBE2ED;

                .setting_col {
                    text-align: left;
                    padding-bottom: 8px;
                    margin-right: 30px;
                    cursor: pointer;

                    &:hover {
                        border-bottom: .51px solid #043854;
                    }

                    &.active_tab {
                        border-bottom: 3px solid #043854;

                    }
                }
            }

            .signup_component {
                height: 400px;
                background-color: white;
                padding-top: 20px;

                .profileheadertitle{
                    font-weight: normal;
                    font-size: 12px;
                    color:#526179;
                    text-align: left;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .changePassHeader {
                    text-align: left;
                    font-weight: 500;
                    margin-top: 8px;
                    font-size: 20px;
                }
    
                .password_section {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 400px;

                    .input_form {
                        border: 1px solid #DBE2ED;
                        box-sizing: border-box;
                        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.07);
                        border-radius: 8px;
                        width: 400px;
                        height: 60px;
                        color: #0D1220;
                    }
                }
    
                .signup_btn {
                    background-color: #42D6C9;
                    border-radius: 100px;
                    border: none;
                    color: #0D1220;
                    padding: 12px 25px;
                    display: block;
                    height: fit-content;
                }

                .alert.alert-info {
                    max-width: 500px;
                    margin: auto;
                    margin-top: 40px;
                }
            }

            .tab_content {}
        }
    }
}