.channeWrapper {
    height: 100vh;
    width: 100%;
    padding-top: 20px;

    .channelHeader {
        font-weight: 500;
        text-align: left;
        font-size: 20px;
    }

    .addChannel {
        text-align: right;

        .add_channel {
            padding: 8px 29px;
            cursor: pointer;

            .plusIcon {
                position: relative;
                width: 19px;
                bottom: 2px;
                right: 8px;
            }
        }
    }

    .channel_table {

        thead {
            th {
                border-bottom: none;
                border-top: none;
                text-align: left;
                font-size: 18px;
                font-weight: 500;
                color: #0D1220;
            }
        }

        td {
            border-top: none;
            text-align: left;

            .more_options {
                color: #8292AA;
                cursor: pointer;
                font-size: 14px;
                margin-right: 2px;

                &:hover {
                    text-decoration: underline;
                }
            }

            .edit_btn {
                color: #8292AA;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            .connection_status {
                width: 130px;
                text-align: left;

                .active {
                    display: inline-block;
                    background: #E9FDF3;
                    padding: 8px;
                    border-radius: 8px;
                }

                .pending {
                    background: #F9C933;
                    padding: 8px;
                    display: inline-block;
                    border-radius: 8px;
                    width: 85px;
                    text-align: center;
                }
            }
        }
    }

    .channel_p {
        text-align: left;
        color: #043854;

    }

    .connect {
        margin-top: 50px;


    }

    .connectBtn {
        background: #EEF3F8;
        border-radius: 100px;
        color: #0D1220;
        border: none;
        padding: 8px 20px;
        float: right;

        .plusIcon {
            width: 20px;
            margin-right: 5px;
            padding-bottom: 3px;
        }
    }
}

.new_channel_wrapper {
    text-align: left;

    .new_chanel_heading {
        margin-bottom: 30px;
    }

    .channel-loader {
        text-align: center;
    }

    .table {
        .addIconDiv {
            border-radius: 90px;
            display: inline-block;
            border: 1px solid #043854;
            width: 80px;
            padding: 2px;
            text-align: center;

            &.disable {
                pointer-events: none;
                color: #ddd;
                border: 1px solid #ddd;

                .add_btn {
                    font-weight: 500;
                    color: #ddd;
                }
            }

            &:hover {
                cursor: pointer;

                .add_btn {
                    font-weight: 500;
                }
            }

            .add_btn {
                color: #043854;
                display: inline-block;
                font-size: 16px;
                font-weight: 400;
            }

            .addIcon {
                position: relative;
                font-size: 12px;
                bottom: 2px;
                right: 3px;
            }
        }

        .new_channel_row {
            border-bottom: none;

            .channel_icon {
                text-align: center;
                position: relative;

                .icon {
                    font-size: 30px;
                    position: relative;
                    top: 4px;
                    color: #C0CBDA;
                }

            }

            .description {
                color: #0D1220;


                .new-tag {
                    font-size: 12px;
                    position: absolute;
                    bottom: -10px;
                    border: 1px solid #2BAE6B;
                    padding: 0 2px;
                    border-radius: 4px;
                    color: #2BAE6B;
                    width: 50px;
                    text-align: center;
                    font-weight: 500;
                }
                .soon{
                    border: 1px solid #043854;
                    font-size: 12px;
                    position: absolute;
                    bottom: -10px;
                    padding: 0 2px;
                    border-radius: 4px;
                    color: #043854;
                }

                .intro {
                    font-size: 14px;
                    color: #8292AA;
                }
            }
        }


        td {
            border-top: none;
            text-align: left;
        }
    }

}

.backdiv {
    margin-bottom: 20px;
    cursor: pointer;

    .backIcon {
        margin-right: 5px;
    }
}

.facebook_wrapper {
    text-align: left;

    .fb_del_channel {
        background-color: #F69195;
        border: none;
        color: black;
    }

    .fbsettings-block {
        padding-top: 50px;
        width: 800px;
        padding: 25px 20px;
        text-align: center;
        box-shadow: 0px 8px 33px 8px rgba(0, 0, 0, 0.07);
        margin: 0 auto;

        .fb-para {
            margin-bottom: 2px;
            font-size: 15px;

            &.small {
                margin-bottom: 15px;
            }
        }

        .success-msg {
            color: #18a0fb;
        }

        .failed-msg {
            color: red;
        }

        .messenger {
            margin-bottom: 15px;
            margin-top: 22px;

            .fb-img {
                width: 50px;
            }
        }

        .span-info {
            font-size: 15px;
        }

        .fb-btn {
            border: 1px solid #1976d3;
            background-color: #1976d3;
            border-radius: 4px;
            color: #fff;
            cursor: pointer;
            padding: 5px 25px;
            display: block;
            margin: 20px auto;
        }
    }

    .facebook_content {
        margin-bottom: 5%;

        .channel_Details {
            margin-top: 70px;
            margin-left: 30px;

            .channelTitle {
                margin-bottom: 30px;
            }

            .facebook-ch-name {
                display: inline-block;
                margin-left: 5px;
            }

            .fb-icon {
                color: #4267B2;
            }
        }
    }
}

.deleteChannelModalWrapper {
    .content{
        font-size: 14px;
    }
    .modalTitle {
        font-weight: 500;
        font-size: 24px;
        color: #0D1220;
        mix-blend-mode: normal;
    }

    .submit_btn {
        width: 132px;
        height: 40px;
        background: #42D6C9;
        border-radius: 100px;
        border: #42D6C9;
        color: black;

    }

    .cancel_btn {
        width: 132px;
        height: 40px;
        background: #ffffff;
        border-radius: 100px;
        border: 1px solid #DBE2ED;
        color: black;

    }
}

.dns_wrapper {

    .dns_table {
        margin-top: 20px;
    }
}

.hide {
    display: none;
}