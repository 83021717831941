.ticketWrapper {
    transition-property: top, bottom, width;
    transition-timing-function: linear, linear, ease;
    z-index: 9;
    background-color: #FFFFFF;
    padding: 0px;

    .createEmailBtn {
        background: transparent;
        border: 1px solid #043854;
        border-radius: 100px;
        padding: 4px 30px;
        font-size: 14px;
        color: #0D1220;
        margin-top: 10px;

        .email_create {
            width: 25px;
            color: #0D1220;
        }
    }

    .setup-pending {
        font-size: 12px;
        padding-top: 10px;
        margin-bottom: 0;
        color: red;
        font-weight: bold;
    }

    .twitterPlatform {
        font-size: 25px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 0;
    }

    .twitterMenu {
        border-bottom: 1px solid #ddd;
        margin-left: 0;
        margin-right: 0;

        .col {
            padding: 10px;

            .menuOption {
                padding: 8px 5px;
                cursor: pointer;

                &.active {
                    background-color: #00acc1;
                    color: #fff;
                    border-radius: 4px;
                    padding: 8px 5px;
                    box-shadow: 0 12px 20px -10px #00acc147, 0 4px 20px 0 #0000001f, 0 7px 8px -5px #00acc133;
                }
            }
        }
    }

    .socialPlatforms {}


    .messageFilter {
        margin-left: 0;
        margin-right: 0;
        // margin-top: 10px;
        // border-bottom: 1px solid #ddd;
        padding: 0px;
        width: 100%;
        margin-left: 0 px;
        border-bottom: 1px solid #ddd;

        .filterOption {
            font-size: 14px;

            &:hover {
                cursor: pointer;
                font-weight: 500;
            }

            &.active {
                border-bottom: 3px solid #043854;
            }
        }
    }

    .header {
        padding: 10px 10px 10px;

        .searchCoversation {
            width: 100%;
            height: 40px;
            display: inline-block;
            background: #FFFFFF;
            border: 1px solid #DBE2ED;
            box-sizing: border-box;
            border-radius: 8px;

            &::placeholder {
                font-size: 14px;
            }
        }
    }
}